import { createContext, Dispatch, ReactNode, SetStateAction, useState } from "react";

import { queries } from "@lib/queries";
import trackEvent from "@lib/trackers";
import { useQuery } from "@tanstack/react-query";
import { useLocation } from "@tanstack/react-router";

import { taskFormRoute } from "../route";

type ContextProps = {
    setShouldSendTracker: Dispatch<SetStateAction<boolean>>;
    sendTracker: (forceSending?: boolean) => void;
};

export const PostingTrackingContext = createContext({
    setShouldSendTracker: () => null,
    sendTracker: () => null,
} as ContextProps);

export function PostingTrackingContextProvider({ children }: { children: ReactNode }) {
    const location = useLocation();
    const step = location.pathname.split("/")[3];
    const { taskId } = taskFormRoute.useParams();
    const { data: task } = useQuery(queries.task.detail(taskId));
    const { data: company } = useQuery(queries.company.detail());
    const [shouldSendTrackerState, setShouldSendTrackerState] = useState(false);

    function setShouldSendTracker(value: boolean) {
        if (task?.origin) {
            setShouldSendTrackerState(value);
        }
    }

    function sendTracker(forceSending = false) {
        (shouldSendTrackerState || forceSending) &&
            task?.origin &&
            trackEvent({
                name: "jobposting - vms draft modification",
                params: {
                    taskId,
                    organisationId: company?.organisation.id,
                    origin: task?.origin || "",
                    step,
                },
            });
        setShouldSendTracker(false);
    }

    return (
        <PostingTrackingContext.Provider value={{ setShouldSendTracker, sendTracker }}>
            {children}
        </PostingTrackingContext.Provider>
    );
}
