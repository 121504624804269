import { Loader } from "side-ui";

import { queries } from "@lib/queries";
import { useQuery } from "@tanstack/react-query";
import { CatchBoundary, Outlet, useMatches } from "@tanstack/react-router";

import { PostingTrackingContextProvider } from "./context/PostingTrackingContext";
import { planningTypeStepRoute, summaryRoute, taskFormRoute } from "./route";
import { TaskFormFooter } from "./TaskFormFooter";
import { TaskFormHeader } from "./TaskFormHeader";

import styles from "./TaskForm.module.css";

const TaskForm = () => {
    const { taskId } = taskFormRoute.useParams();
    const { isLoading: isTaskLoading } = useQuery(queries.task.detail(taskId));

    const matches = useMatches();
    const matchSummaryOrPlanningType = Boolean(
        matches.find(
            (match) =>
                match.routeId === summaryRoute.id || match.routeId === planningTypeStepRoute.id,
        ),
    );

    if (isTaskLoading) {
        return (
            <div className={styles.loadingLayout}>
                <Loader />
            </div>
        );
    }

    return (
        <div className={styles.taskFormLayout}>
            <PostingTrackingContextProvider>
                <TaskFormHeader />
                <CatchBoundary getResetKey={() => "reset"}>
                    <div className={styles.content}>
                        <Outlet />
                    </div>
                    {!matchSummaryOrPlanningType ? <TaskFormFooter /> : null}
                </CatchBoundary>
            </PostingTrackingContextProvider>
        </div>
    );
};

export default TaskForm;
